<template>
	<div class="container">
		<div class="title">
			<div class="text">添加标签</div>
		</div>
		<div class="content">
			<div class="row">
				<div class="left">中文标题</div>
				<div class="right">
					<input class="input" v-model="info.tag_name" placeholder="" />
				</div>
			</div>
			<div class="row">
				<div class="left">中文图片</div>
				<div class="right">
					<div class="upload-img">
						<input class="file" type="file" value="" data-attribute="car_tag" @change="uploadFile" />
						<img class="img" :src="info.car_tag" :onerror="defaultImg">
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">维语标题</div>
				<div class="right">
					<input class="input rtl" v-model="info.tag_name_ug" placeholder="" />
				</div>
			</div>
			<div class="row">
				<div class="left">维文图片</div>
				<div class="right">
					<div class="upload-img">
						<input class="file" type="file" value="" data-attribute="car_tag_ug" @change="uploadFile" />
						<img class="img" :src="info.car_tag_ug" :onerror="defaultImg">
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">哈语标题</div>
				<div class="right">
					<input class="input rtl" v-model="info.tag_name_kz" placeholder="" />
				</div>
			</div>
			<div class="row">
				<div class="left">哈文图片</div>
				<div class="right">
					<div class="upload-img">
						<input class="file" type="file" value="" data-attribute="car_tag_kz" @change="uploadFile" />
						<img class="img" :src="info.car_tag_kz" :onerror="defaultImg">
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">排序号</div>
				<div class="right">
					<input type="number" class="input" v-model="info.tag_sort" placeholder="">
				</div>
			</div>
			<div class="row">
				<div class="left">显示</div>
				<div class="right">
					<switcha class="switcha" :on="info.tag_status==1" v-on:changed="changeDisabled" />
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" type="submit" @click="add">添加</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		addUsedCarTag
	} from "@/api/used-car.js";
	import {
		uploadFile
	} from "@/api/common";

	import Switcha from "@/components/widgets/Switch.vue";
	export default {
		components: {
			Switcha
		},
		data() {
			return {
				info: {
					tag_name: "",
					tag_name_ug: "",
					tag_name_kz: "",
					car_tag: "",
					car_tag_ug: "",
					car_tag_kz: "",
					tag_sort: "",
					tag_status: 1,
				},
				toastText: "请正确输入参数",
				defaultImg: "this.src='https://izpak-admin.elghar.cn/assets/img/default.png'",
				isShowToast: false,
			};
		},
		methods: {
			add() {
				if (isLoad) {
					this.showToast("请稍等！");
					return;
				}
				if (!this.info.tag_name) {
					this.showToast("请输入中文标题！");
					return;
				}
				if (!this.info.tag_name_ug) {
					this.showToast("请输入维文标题！");
					return;
				}
				if (!this.info.tag_name_kz) {
					this.showToast("请输入哈文标题！");
					return;
				}
				if (!this.info.car_tag) {
					this.showToast("请输入上传中文图片");
					return;
				}
				if (!this.info.car_tag_ug) {
					this.showToast("请输入上传维文图片");
					return;
				}
				if (!this.info.car_tag_kz) {
					this.showToast("请输入上传哈文图片");
					return;
				}
				this.info.tag_sort *= 1;
				isLoad = true;
				addUsedCarTag(this.info)
					.then((res) => {
						if (res.status == 200) {
							this.showToast("添加成功");
							setTimeout(() => {
								this.$router.back();
							}, 500);
						} else {
							this.showToast(res.message);
						}
						isLoad = false;
					})
					.catch(() => {
						isLoad = false;
						this.showToast("出错了！");
					});
			},
			uploadFile(event) {
				let self = this;
				let file = event.target.files[0];
				let attribute = event.target.dataset.attribute;
				if (file) {
					//创建用来读取此文件的对象
					let reader = new FileReader();
					//使用该对象读取file文件
					reader.readAsDataURL(file);
					//读取文件成功后执行的方法函数
					reader.onload = function() {
						this.formData = new FormData();
						this.formData.append("image", file, file.name);
						//读取成功后返回的一个参数e，整个的一个进度事件
						uploadFile(this.formData).then((res) => {
							if (res.status == 200) {
								self.info[attribute] = res.data.url;
								self.showToast("上传成功");
							} else {
								self.showToast("失败：" + res.message);
							}

						});
					};
				}
			},
			changeDisabled() {
				this.info.tag_status = this.info.tag_status == 1 ? 0 : 1
			},
			cancel() {
				this.$router.back();
			},
			inputChange(e) {
				e.returnValue = /[\d]/.test(String.fromCharCode(e.keyCode));
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		},
	};
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
</style>
